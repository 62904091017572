.skill-popup {
  position: absolute;
  top: -110%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #2c2c6c;
  color: white;
  padding: 1rem;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 10;
  width: calc(100% - 2rem); /* Full width with some padding */
  max-width: 300px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.2s, opacity 0.2s, transform 0.2s;
}

.skill-card:hover .skill-popup {
  visibility: visible;
  opacity: 1;
  transform: translateX(-50%) translateY(-110%);
}
