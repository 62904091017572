body {
  background: hsla(279, 88%, 67%, 1);
  background: linear-gradient(135deg, hsla(279, 88%, 67%, 1) 0%, hsla(0, 71%, 57%, 1) 100%);
  background: -moz-linear-gradient(135deg, hsla(279, 88%, 67%, 1) 0%, hsla(0, 71%, 57%, 1) 100%);
  background: -webkit-linear-gradient(135deg, hsla(279, 88%, 67%, 1) 0%, hsla(0, 71%, 57%, 1) 100%);
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#C162F5", endColorstr="#DF4444", GradientType=1 );
  color: white;
  font-family: 'Poppins', sans-serif;
  margin: 0;
  height: 100%;
  overflow: auto;
}

header {
  background-color: #1f1f38;
  padding: 1rem;
  z-index: 1; /* Ensure header is above ripple */
  font-family: inherit; /* Inherit font family from body */
}

nav a {
  color: white;
  margin: 0 1rem;
  transition: color 0.3s;
  font-family: inherit; /* Inherit font family from body */
}

nav a:hover {
  color: #f14e95;
}

.login-button {
  background: linear-gradient(315deg, hsla(279, 88%, 67%, 1) 0%, hsla(0, 71%, 57%, 1) 100%);
  background: -moz-linear-gradient(315deg, hsla(279, 88%, 67%, 1) 0%, hsla(0, 71%, 57%, 1) 100%);
  background: -webkit-linear-gradient(315deg, hsla(279, 88%, 67%, 1) 0%, hsla(0, 71%, 57%, 1) 100%);
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#DF4444", endColorstr="#C162F5", GradientType=1 );
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  text-decoration: none;
  transition: background 0.3s;
  font-family: inherit; /* Inherit font family from body */
}

.login-button:hover {
  background: linear-gradient(135deg, hsla(0, 71%, 57%, 1) 0%, hsla(279, 88%, 67%, 1) 100%);
  background: -moz-linear-gradient(135deg, hsla(0, 71%, 57%, 1) 0%, hsla(279, 88%, 67%, 1) 100%);
  background: -webkit-linear-gradient(135deg, hsla(0, 71%, 57%, 1) 0%, hsla(279, 88%, 67%, 1) 100%);
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#C162F5", endColorstr="#DF4444", GradientType=1 );
}

.skill-logo {
  width: 24px;
  height: 24px;
  object-fit: cover;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

section {
  margin-bottom: 2rem;
}

h1, h2 {
  margin-bottom: 1rem;
  text-align: center;
  font-family: inherit; /* Inherit font family from body */
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2rem;
}

.text-center {
  text-align: center;
}

.skills-section, .projects-section, .education-section {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.skill-card, .project-card, .education-card {
  background-color: #2c2c6c;
  padding: 1rem;
  margin: 1rem;
  border-radius: 10px;
  width: 30%;
  min-width: 200px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.skill-card ul, .project-card p, .education-card p {
  margin: 0.5rem 0;
}

footer {
  background-color: #1f1f38;
  padding: 1rem;
  text-align: center;
  z-index: 1; /* Ensure footer is above ripple */
}

.skill-card ul, .project-card ul, .education-card ul {
  list-style: none;
  padding: 0;
}

.skill-card ul li, .project-card ul li, .education-card ul li {
  margin: 0.5rem 0;
}

/* Modal styles */
.modal {
  background-color: #2c2c6c;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 90%;
  margin: 0 auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  color: white;
  z-index: 2;
  font-family: inherit; /* Inherit font family from body */
}

.overlay {
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.modal input,
.modal textarea {
  width: calc(100% - 22px); /* Adjust width to ensure no overflow */
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #444;
  color: white;
  font-family: inherit; /* Inherit font family from body */
}

.modal button {
  background: linear-gradient(315deg, hsla(279, 88%, 67%, 1) 0%, hsla(0, 71%, 57%, 1) 100%);
  background: -moz-linear-gradient(315deg, hsla(279, 88%, 67%, 1) 0%, hsla(0, 71%, 57%, 1) 100%);
  background: -webkit-linear-gradient(315deg, hsla(279, 88%, 67%, 1) 0%, hsla(0, 71%, 57%, 1) 100%);
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#DF4444", endColorstr="#C162F5", GradientType=1 );
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  text-decoration: none;
  transition: background 0.3s;
  font-family: inherit; /* Inherit font family from body */
}

.modal button:hover {
  background: linear-gradient(135deg, hsla(0, 71%, 57%, 1) 0%, hsla(279, 88%, 67%, 1) 100%);
  background: -moz-linear-gradient(135deg, hsla(0, 71%, 57%, 1) 0%, hsla(279, 88%, 67%, 1) 100%);
  background: -webkit-linear-gradient(135deg, hsla(0, 71%, 57%, 1) 0%, hsla(279, 88%, 67%, 1) 100%);
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#C162F5", endColorstr="#DF4444", GradientType=1 );
}

.octagon {
  width: 150px;
  height: 150px;
  position: relative;
  margin: 50px auto;
  clip-path: polygon(30% 3%, 70% 3%, 97% 30%, 97% 70%, 70% 97%, 30% 97%, 3% 70%, 3% 30%);
}

.octagon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.small-photo {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
  margin: 1rem;
}

#ripple-container {
  position: fixed; /* Make it fixed to cover the whole viewport */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; /* Cover the entire viewport height */
  z-index: -1; /* Place it behind all other elements */
  pointer-events: none; /* Ensure it does not interfere with other elements */
}

/* Realistic ripple effect */
.ripple {
  position: absolute;
  border-radius: 50%;
  background: radial-gradient(circle, rgba(9, 231, 213, 0.616) 15%, transparent 20%);
  background-size: 100% 100%;
  transform: scale(0);
  animation: ripple-animation 1.2s linear;
  z-index: 0;
}

@keyframes ripple-animation {
  to {
    transform: scale(2);
    opacity: 0;
  }
}

