.skills-container {
  display: flex;
  justify-content: space-between; /* Ensure groups are evenly spaced */
  flex-wrap: wrap;
  gap: 20px; /* Add some gap between the groups */
}

.skills-group {
  background-color: #2c2c6c;
  padding: 1rem;
  border-radius: 10px;
  width: 30%;
  min-width: 200px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.skills-group-title {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: white;
}

.skill-card {
  display: flex;
  align-items: center;
  justify-content: center; /* Center content horizontally */
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  transition: transform 0.2s, box-shadow 0.2s;
  border-radius: 5px;
  position: relative; /* Ensure skill-popup is positioned relative to the skill card */
  width: 100%; /* Ensure the card takes the full width */
  box-sizing: border-box; /* Include padding in the element's total width and height */
}

.skill-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.skill-logo {
  width: 24px;
  height: 24px;
  object-fit: cover;
  margin-right: 0.5rem;
}

.skill-card p {
  margin: 0;
  color: white;
  text-align: center; /* Center text */
  flex: 1; /* Allow text to take up available space */
}
