.image-modal {
    background-color: #2c2c6c;
    color: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    height: 400px;
    margin: 0 auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    z-index: 2;
    overflow: hidden; /* Ensure content stays within bounds */
  }
  
  .overlay {
    background-color: rgba(0, 0, 0, 0.75);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto; /* Allow scrolling */
  }
  
  .image-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .full-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
  